var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.amountOfDepartments>1 || _vm.isNavbar || _vm.redirectBackTo!=null)?_c('div',{class:{
    navbar: _vm.isNavbar,
    button: !_vm.isNavbar && _vm.hasTurnedToNavbar && !_vm.isApplication,
    applicationButton: !_vm.isNavbar && _vm.hasTurnedToNavbar && _vm.isApplication,
    boxShadow: !_vm.areCategoriesVisible || _vm.hasBoxShadow,
    extraHeight: _vm.isNavbar && _vm.hasBoxShadow,
    smallerTop: _vm.isApplication,
    biggerTop: !_vm.isApplication
  },attrs:{"id":"back-to-services"}},[(_vm.amountOfDepartments>1 || _vm.redirectBackTo!=null)?_c('font-awesome-icon',{class:{ stayLeft: _vm.isNavbar },attrs:{"id":"back-to-services-icon","icon":"arrow-left"},on:{"click":_vm.goToServices}}):_vm._e(),(_vm.isNavbar && (_vm.restaurantType=='MENU' || _vm.restaurantType=='REQUESTS'))?_c('p',{attrs:{"id":"service-label"},on:{"click":function($event){return _vm.openCategoriesModal()}}},[(_vm.amountOfDepartments>1 || _vm.redirectBackTo!=null)?_c('span',[_vm._v(_vm._s(_vm.serviceName[_vm.language].name)+" -")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.labels.categories.categoriesHeader[_vm.language])+" "),_c('font-awesome-icon',{attrs:{"icon":"chevron-down"}})],1)]):(_vm.isNavbar && (_vm.restaurantType=='INFO' || _vm.restaurantType=='OPENINGS'))?_c('p',{attrs:{"id":"service-label"}},[_c('span',[_vm._v(_vm._s(_vm.serviceName[_vm.language].name))])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }