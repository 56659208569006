

<template>
  <div id="choose">
    <ChooseCategory></ChooseCategory>
    <div id="categories">
      <div class="category">
        <FoodCategory
          v-for="category in categories"
          v-bind:key="category.id"
          v-bind:category="category"
        ></FoodCategory>
      </div>
    </div>
  </div>
</template>

<script>
import FoodCategory from "./FoodCategory";
import ChooseCategory from "./ChooseCategory";

export default {
  name: "Categories",
  components: {
    FoodCategory,
    ChooseCategory,
  },
  computed: {
    categories() {
      return this.$store.getters.getCategories;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
#categories {
  padding: 8%;
  color: gray;
  font-weight: bold;
  max-height: 52.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

#categories::-webkit-scrollbar {
  width: 8px;
}

#categories::-webkit-scrollbar-thumb {
  background-color: #3b3838;
  border-radius: 40px;
  border: 1.9px solid white;
  z-index: 0;
}

#choose {
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.16), 3px 3px 4px rgba(0, 0, 0, 0.23);
  background: white;
  border-radius: 18px 18px 10px 18px;
}
</style>



