<template>
  <div
    v-if="category"
    role="dialog"
    :class="{
      [category.anchor]: true,
      category: true,
      selected: category.isActive,
    }"
    :id="`${category.anchor}-id`"
    @click="scrollToCategory(category)"
  >
    <p
      id="category-name"
      :class="{ selectedText: category.isActive }"
    >{{ getCategoryName(category) }}</p>
  </div>
</template>

<script>
export default {
  name: "HorizontalCategory",
  props: ["category"],
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
    selectedExtraLanguage() {
      return this.$store.getters.getSelectedExtraLanguage;
    },
  },
  methods: {
    getCategoryName(category) {
      if (this.selectedExtraLanguage != null && category.names[this.selectedExtraLanguage] && category.names[this.selectedExtraLanguage].name != null && category.names[this.selectedExtraLanguage].name.trim().length != 0) {
        return category.names[this.selectedExtraLanguage].name;
      }
      return category.names[this.language].name;
    },
    goToCategory() { },

    scrollToCategory() {
      let yOffset = -window.screen.height * 0.08 - 49

      const y =
        document.getElementById(this.category.anchor).getBoundingClientRect()
          .top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y });

      let el = document.getElementById(`${this.category.anchor}-id`);
      const elLeft = el.offsetLeft + el.offsetWidth;
      const elRight = el.offsetLeft;
      const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

      // check if element not in view
      if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
        el.parentNode.scrollLeft =
          elRight - el.parentNode.offsetWidth / 2 - el.offsetWidth / 2;
      } else if (
        elLeft <=
        el.parentNode.offsetLeft + el.parentNode.scrollLeft
      ) {
        el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
      } else if (elRight < el.parentNode.scrollLeft) {
        el.parentNode.scrollLeft =
          elRight - el.parentNode.offsetWidth / 2 + el.offsetWidth;
      }
    },
  },
};
</script>

<style scoped>
.category {
  display: flex;
  margin: 0;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem !important;
  white-space: nowrap;
  margin: 0 10px 0 10px;
  background: transparent;
  border-radius: 4px;
}

#category-name {
  display: inline-block;
  margin: 0 !important;
  color: #333;
  cursor: pointer;
  font-size: 13px;
  text-decoration: unset !important;
  cursor: unset !important;
  font-weight: 600;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
@media screen and (max-width: 320px) {
  #category-name {
    font-size: 11.8px;
  }
}

#category-name:hover {
  text-decoration: underline;
}

.selectedText {
  color: white !important;
  font-weight: 700 !important;
}

.selected {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  background: #333 !important;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>