<template>
  <div
    :class="{
      showNavbar: isNavbar && hasNavbarOpened && !multipleServices,
      showNavbarWithServices: isNavbar && hasNavbarOpened && multipleServices,
      hideNavbar: !isNavbar && hasNavbarOpened && !multipleServices,
      hideNavbarWithServices: !isNavbar && hasNavbarOpened && multipleServices
    }"
    v-if="categories"
    id="categories-navbar"
  >
    <HorizontalCategory v-for="category in categories" :key="category.id" :category="category"></HorizontalCategory>
  </div>
</template>

<script>
import HorizontalCategory from "./HorizontalCategory.vue";
export default {
  components: { HorizontalCategory },
  name: "CategoriesNavbar",
  props: ["multipleServices"],
  mounted() {
    this.categoriesToShow = this.categories;

    window.addEventListener("scroll", this.showCategoriesNavbar);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.showCategoriesNavbar);
  },
  computed: {
    categories() {
      return this.$store.getters.getCategories;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      isNavbar: false,
      hasNavbarOpened: false,
    };
  },
  methods: {
    categoryClassName(category) {
      return category.names.translation["ENG"].name.replace(" ", "-");
    },

    showCategoriesNavbar: function () {
      let element = document.getElementById("menu-container");
      var rectangle = element.getBoundingClientRect();

      if (rectangle.top <= 0 + this.$screen.height * 0.2) {
        this.isNavbar = true;
        this.$store.dispatch("categoriesAreVisible")
        if (!this.hasNavbarOpened) {
          this.hasNavbarOpened = true;
        }
      } else if (rectangle.top > 0 + this.$screen.height * 0.2) {
        this.isNavbar = false;
        this.$store.dispatch("categoriesAreNotVisible")
      }
    },
  },
};
</script>
<style scoped>
#categories-navbar {
  position: fixed;
  top: -8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 70vw;
  height: 8vh;
  min-height: 45px;
  max-height: 100px;
  background: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: 203;
}
@media screen and (max-width: 1285px) {
  #categories-navbar {
    width: 100vw;
  }
}

.showNavbar {
  animation: showNavbar 0.3s forwards ease-in-out;
}

.showNavbarWithServices {
  animation: showNavbarWithServices 0.35s forwards ease-in-out;
}

.hideNavbar {
  animation: hideNavbar 0.3s forwards ease-in-out;
}

.hideNavbarWithServices {
  animation: hideNavbarWithServices 0.35s forwards ease-in-out;
}

@keyframes showNavbar {
  from {
    top: -8vh;
  }

  to {
    top: 0;
  }
}

@keyframes showNavbarWithServices {
  from {
    top: -8vh;
  }

  to {
    top: 49px;
  }
}

@keyframes hideNavbar {
  from {
    top: 0;
  }

  to {
    top: -8vh;
  }
}

@keyframes hideNavbarWithServices {
  from {
    top: 40px;
  }

  to {
    top: -8vh;
  }
}

#categories-navbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>