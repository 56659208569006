<template>
  <div
    v-if="amountOfDepartments>1 || isNavbar || redirectBackTo!=null"
    :class="{
      navbar: isNavbar,
      button: !isNavbar && hasTurnedToNavbar && !isApplication,
      applicationButton: !isNavbar && hasTurnedToNavbar && isApplication,
      boxShadow: !areCategoriesVisible || hasBoxShadow,
      extraHeight: isNavbar && hasBoxShadow,
      smallerTop: isApplication,
      biggerTop: !isApplication
    }"
    id="back-to-services"
  >
    <font-awesome-icon
      @click="goToServices"
      id="back-to-services-icon"
      :class="{ stayLeft: isNavbar }"
      icon="arrow-left"
      v-if="amountOfDepartments>1 || redirectBackTo!=null"
    ></font-awesome-icon>
    <p
      id="service-label"
      v-if="isNavbar && (restaurantType=='MENU' || restaurantType=='REQUESTS')"
      @click="openCategoriesModal()"
    >
      <span v-if="amountOfDepartments>1 || redirectBackTo!=null">{{ serviceName[language].name }} -</span>
      <span>
        {{ labels.categories.categoriesHeader[language] }}
        <font-awesome-icon icon="chevron-down" />
      </span>
    </p>
    <p
      id="service-label"
      v-else-if="isNavbar && (restaurantType=='INFO' || restaurantType=='OPENINGS')"
    >
      <span>{{ serviceName[language].name }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "BackToServices",
  props: ["table", "storeName", "hasBoxShadow", "isApplication", "redirectBackTo", "amountOfDepartments", "restaurantType"],
  mounted() {
    window.addEventListener("scroll", this.isInViewport);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.isInViewport);
  },
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    serviceName() {
      return this.$store.getters.getServiceName;
    },

    areCategoriesVisible() {
      return this.$store.getters.areCategoriesVisible;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      isNavbar: false,
      isButton: false,
      hasTurnedToNavbar: false,
    };
  },
  methods: {
    isInViewport: function () {
      if (window.pageYOffset >= 80 && !this.isNavbar) {
        this.isNavbar = true;
        this.isButton = false;
        this.hasTurnedToNavbar = true;
      } else if (window.pageYOffset < 80) {
        if (!this.isButton) {
          this.isButton = true;
          this.isNavbar = false;
        }
      }
    },

    goToServices: function () {
      if (this.redirectBackTo != null) {
        this.$router.push({ name: "services", params: { tableId: this.redirectBackTo } });
      } else {
        this.$router.push({ name: "services", params: { tableId: this.table } });
      }
    },
    openCategoriesModal() {
      this.$store.dispatch("openCategoriesModal");
    }
  },
};
</script>

<style scoped>
#back-to-services {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  border-radius: 40px;
  cursor: pointer !important;
  z-index: 204;
  will-change: scroll-position;
  contain: strict;
}

.biggerTop {
  top: 80px;
}

.smallerTop {
  top: 23px;
}

.boxShadow {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36) !important;
}

#service-label {
  display: flex;
  align-self: center !important;
  justify-self: center !important;
  margin: 0;
  font-size: 14.5px;
  font-weight: 800;
  color: #003340;
  max-width: 75%;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden;
  display: inline-block;
}

.navbar {
  animation: turnToNavbar 0.3s forwards ease-in-out;
}

@media screen and (max-width: 1285px) {
  @keyframes turnToNavbar {
    from {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      top: 80px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24),
        2px 2px 4px rgba(0, 0, 0, 0.36);
      transform: translateX(0px);
    }

    to {
      padding: 35px 10px 20px 10px;
      padding-left: 5.5%;
      width: 100vw;
      height: 49px;
      border-radius: 0;
      top: 0;
      box-shadow: none;
      transform: translateX(-20px);
    }
  }
}

.button {
  animation: turnToButton 0.3s forwards;
}

.applicationButton {
  animation: turnToButtonForApplication 0.3s forwards;
}

@media screen and (max-width: 1285px) {
  @keyframes turnToButton {
    from {
      width: 100vw;
      padding-left: 5.5%;
      border-radius: 0;
      top: 0;
      left: 0;
    }

    to {
      width: 40px;
      border-radius: 40px;
      top: 80px;
      left: 20px;
      height: 40px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24),
        2px 2px 4px rgba(0, 0, 0, 0.36);
    }
  }

  @keyframes turnToButtonForApplication {
    from {
      width: 100vw;
      padding-left: 5.5%;
      border-radius: 0;
      top: 0;
      left: 0;
    }

    to {
      width: 40px;
      border-radius: 40px;
      top: 23px;
      left: 20px;
      height: 40px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24),
        2px 2px 4px rgba(0, 0, 0, 0.36);
    }
  }
}

@media (min-width: 1285px) {
  #service-label {
    display: none;
  }
}

#back-to-services-icon {
  position: absolute;
  left: unset;
  font-size: 19px;
  color: #003340;
  margin: 0;
}

.stayLeft {
  left: 15px !important;
}

.extraHeight {
  height: 65px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
</style>